<template>
  <template v-if="!$parent.preview">
    <template v-if="loader.customObject">
      <div>
        <p class="placeholder-glow">
          <span class="placeholder m-1 col-7"></span>
          <span class="placeholder m-1 col-4"></span>
          <span class="placeholder m-1 col-4"></span>
          <span class="placeholder m-1 col-6"></span>
          <span class="placeholder m-1 col-8"></span>
          <span class="placeholder m-1 col-7"></span>
          <span class="placeholder m-1 col-4"></span>
          <span class="placeholder m-1 col-4"></span>
          <span class="placeholder m-1 col-6"></span>
          <span class="placeholder m-1 col-8"></span>
        </p>
      </div>
    </template>
    <template
      v-else-if="!loader.customObject && customObjectItems?.length <= 0"
    >
      <div class="alert alert-warning">
        {{
          $t(
            "Components.PageDesignerModal.CustomObjectItemNotFound",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}
      </div>
    </template>
    <div class="page-designer" v-else>
      <div class="page-designer-header">
        <div class="row exclude-divs ms-1 me-1 mt-2">
          <div class="col-md-8 col-xs-12 col-sm-6">
            <div class="col">
              <h5 class="page-designer-tab-title">
                {{
                  $t(
                    "Components.PageDesignerModal.CustomObjectItems",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h5>
            </div>
            <div class="col">
              <input
                class="form-control mb-2 mt-1"
                :placeholder="
                  $t(
                    'Components.PageDesignerModal.Search',
                    {},
                    {
                      locale: this.$store.state.activeLang,
                    }
                  )
                "
                v-model="searchText"
              />

              <div
                class="btn-group mb-2 d-grid gap-1 d-md-flex"
                role="group"
                aria-label="Custom Object Filters"
              >
                <input
                  type="checkbox"
                  class="btn-check"
                  :id="`toggle-fields-${pageLayout.publicId}`"
                  :autocomplete="this.$isAutoComplete"
                  :checked="showFields"
                  @change="showFields = !showFields"
                />
                <label
                  class="btn"
                  :class="getCustomObjectClass('field')"
                  :for="`toggle-fields-${pageLayout.publicId}`"
                >
                  <i
                    class="bi"
                    :class="showFields ? 'bi-check-circle-fill' : 'bi-circle'"
                  ></i>
                  {{
                    $t(
                      "Components.PageDesignerModal.Fields",
                      {},
                      {
                        locale: this.$store.state.activeLang,
                      }
                    )
                  }}</label
                >

                <input
                  type="checkbox"
                  class="btn-check"
                  :id="`toggle-lookups-${pageLayout.publicId}`"
                  :autocomplete="this.$isAutoComplete"
                  :checked="showLookups"
                  @change="showLookups = !showLookups"
                />
                <label
                  class="btn"
                  :class="getCustomObjectClass('lookup')"
                  :for="`toggle-lookups-${pageLayout.publicId}`"
                >
                  <i
                    class="bi"
                    :class="showLookups ? 'bi-check-circle-fill' : 'bi-circle'"
                  ></i>
                  {{
                    $t(
                      "Components.PageDesignerModal.ObjectRelations",
                      {},
                      {
                        locale: this.$store.state.activeLang,
                      }
                    )
                  }}</label
                >

                <input
                  type="checkbox"
                  class="btn-check"
                  :id="`toggle-buttons-${pageLayout.publicId}`"
                  :autocomplete="this.$isAutoComplete"
                  :checked="showButtons"
                  @change="showButtons = !showButtons"
                />
                <label
                  class="btn"
                  :class="getCustomObjectClass('button')"
                  :for="`toggle-buttons-${pageLayout.publicId}`"
                >
                  <i
                    class="bi"
                    :class="showButtons ? 'bi-check-circle-fill' : 'bi-circle'"
                  ></i>
                  {{
                    $t(
                      "Components.PageDesignerModal.Buttons",
                      {},
                      {
                        locale: this.$store.state.activeLang,
                      }
                    )
                  }}</label
                >
              </div>

              <draggable
                class="custom-object-items"
                :list="filteredItems"
                item-key="id"
                ghost-class="ghost"
                v-bind="{
                  group: 'element-group',
                  sort: true,
                  disabled: false,
                  animation: 200,
                }"
                :move="onMove"
              >
                <template #item="{ element }">
                  <button
                    type="button"
                    class="m-1 btn btn-sm"
                    :class="getCustomObjectClass(element.type)"
                    @dblclick="openObjectInNewWindow(element)"
                  >
                    <i
                      class="bi bi-lg m-1"
                      :class="getCustomObjectIcon(element.type)"
                    ></i>
                    {{ element.name }}
                  </button>
                </template>
              </draggable>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 col-sm-6">
            <div class="col">
              <h5 class="page-designer-tab-title">
                {{
                  $t(
                    "Components.PageDesignerModal.DesignItems",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h5>
            </div>
            <div class="col">
              <div class="page-designer-design-items-parent">
                <draggable
                  class="design-items"
                  :list="designItems"
                  item-key="id"
                  ghost-class="ghost"
                  v-bind="{
                    group: {
                      name: 'element-group',
                      pull: 'clone',
                      put: false,
                    },
                    sort: false,
                    disabled: false,
                    animation: 200,
                  }"
                  :clone="onClone"
                  :move="onMove"
                >
                  <template #item="{ element }">
                    <button type="button" class="btn btn-secondary">
                      {{ element.name }}
                    </button>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-designer-design">
        <div class="col-md-12 col-xs-12 col-sm-12 page-designer-design-title">
          {{
            $t(
              "Components.PageDesignerModal.DesignArea",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
        <DesignItemEdit ref="designItemEdit" :pageLayout="pageLayout" />
        <draggable
          :list="designedItems"
          class="designed-items"
          item-key="id"
          handle=".handle"
          chosenClass="chosen"
          ghost-class="ghost"
          v-bind="{
            group: 'element-group',
            disabled: false,
            animation: 200,
            swapThreshold: 0.6,
            filter: '.locked-element',
          }"
          :move="onMove"
        >
          <template #item="{ element, index }">
            <DesignItem
              :element="element"
              :index="index"
              :customObjectItems="customObjectItems"
              :items="designedItems"
            ></DesignItem>
          </template>
        </draggable>
      </div>
    </div>
  </template>
  <div class="preview px-3" v-else>
    <PreviewItem
      v-for="element in designedItems"
      :key="element.id"
      :element="element"
      :items="designedItems"
    />
  </div>
</template>

<script>
import DesignItemEdit from "./DesignItemEdit.vue";
import draggable from "vuedraggable";
import DesignItem from "./DesignItem.vue";
import PreviewItem from "./preview/PreviewItem.vue";
import { v4 as uuidv4 } from "uuid";
import onMove from "./helpers/OnMove";
import customItemCssHelper from "./helpers/CustomItemCssHelper";
import openObjectDetailHelper from "./helpers/OpenObjectDetailHelper";

export default {
  props: ["pageLayout"],
  name: "DesignerLayoutBase",
  components: {
    draggable,
    DesignItem,
    PreviewItem,
    DesignItemEdit,
  },
  data() {
    return {
      searchText: "",
      designItems: [
        {
          type: "container",
          name: "Container",
          container: true,
          items: [],
          className: "container-fluid",
        },
        {
          type: "row",
          name: "Row",
          container: true,
          row: true,
          items: [],
          className: "row",
        },
        {
          type: "column",
          name: "Column",
          columnSize: 0,
          classType: "col", // just type, eg: col, col-xl, col-md, etc.
          className: "col", // with or without size, eg: col-6, col-sm-12, etc.
          container: true,
          items: [],
        },
        {
          type: "panel",
          name: "Panel",
          title: "Title",
          color: "light",
          columnSize: 0,
          container: true,
          items: [],
          isCollapsed: false,
        },
        {
          type: "tab",
          name: "Tab",
          columnSize: 0,
          tabType: "nav-tabs",
          isJustified: false,
          items: [
            {
              id: uuidv4(),
              type: "tabItem",
              name: "Item #1",
              container: true,
              items: [],
            },
          ],
        },
        {
          type: "accordion",
          name: "Accordion",
          columnSize: 0,
          items: [
            {
              id: uuidv4(),
              type: "accordionItem",
              name: "Item #1",
              content: "",
              container: true,
              items: [],
            },
          ],
        },
        {
          type: "alert",
          name: "Alert",
          title: "",
          message: this.$t(
            "Components.PageDesignerModal.AlertMessage",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          ),
          icon: "",
          color: "primary",
          columnSize: 0,
        },
        {
          type: "border",
          name: "Border",
          columnSize: 0,
          color: "",
          borderClass: "",
          container: true,
          items: [],
        },
      ],
      customObjectItems: [],
      designedItems: [],
      initialItems: [
        {
          type: "container",
          name: "Container",
          container: true,
          items: [],
          id: uuidv4(),
          className: "container-fluid",
        },
      ],
      loader: {
        customObject: false,
      },
      showFields: true,
      showLookups: true,
      showButtons: true,
      inUsedCustomObjectIdList: [],
    };
  },
  created: function () {
    if (!this.pageLayout?.layout) {
      this.designedItems = JSON.parse(JSON.stringify(this.initialItems));
    }
  },
  watch: {
    designedItems: {
      handler(includedItems) {
        this.inUsedCustomObjectIdList = [];
        this.findInUsedChildCustomObjectItems({
          items: includedItems,
        });

        if (
          this.pageLayout?.layout === JSON.stringify(includedItems) ||
          JSON.stringify(this.initialItems) === JSON.stringify(includedItems)
        ) {
          return;
        }
        this.$parent.dataHasChanged = true;
        this.$parent.pageLayout.layout = JSON.stringify(includedItems);
      },
      deep: true,
    },
    searchText: {
      handler(text) {
        let customObjects = document.getElementsByClassName("custom-object");
        customObjects.forEach((element) => {
          if (element.getAttribute("name-value").toLowerCase().includes(text)) {
            element.classList.remove("bg-white");
            element.classList.add("bg-info");
            setTimeout(() => {
              element.classList.remove("bg-info");
              element.classList.add("bg-white");
            }, 500);
          }
        });
      },
    },
    "pageLayout.reload": function () {
      if (String.isNullOrWhiteSpace(this.pageLayout.reload)) return;
      this.loader.customObject = true;
      this.$parent.isLoading = true;
      this.customObjectItems = [];
      Promise.all([
        this.getFieldItems(),
        this.getLookupItems(),
        this.getButtonItems(),
      ]).then((values) => {
        values.forEach((value) => {
          let isLookupResult =
            value.config.url.indexOf("LookupRelationList") > -1;
          let isButtonResult = value.config.url.indexOf("ButtonList") > -1;
          if (value.data?.itemCount > 0) {
            let data = value.data.items.map((x) => {
              if (isLookupResult) {
                return {
                  id: x.key,
                  name: x.value,
                  type: "lookup",
                  columnSize: 0,

                  // isTableSheet: false,
                };
              }
              if (isButtonResult) {
                return {
                  id: x.key,
                  name: x.value,
                  type: "button",
                  columnSize: 0,
                };
              }
              return {
                id: x.publicId,
                name: x.name,
                type: "field",
                columnSize: 0,

                description: x.description,
                fieldType: x.fieldType,
                formulaName: x.formulaName,
              };
            });
            this.customObjectItems = [...this.customObjectItems, ...data];
          }
        });
        this.inUsedCustomObjectIdList = [];
        this.findInUsedChildCustomObjectItems({
          items: this.designedItems,
        });

        if (this.pageLayout?.layout) {
          let designedItems = JSON.parse(this.pageLayout.layout);

          let customObjectItems = this.customObjectItems;

          let setNecessaryProperties = function (item) {
            if (item && item.items?.length > 0) {
              item.items.forEach((child) => {
                let obj = customObjectItems.find((x) => x.id === child.id);
                if (
                  child.type === "field" ||
                  child.type === "lookup" ||
                  child.type === "button"
                ) {
                  child["name"] = obj?.name;
                  child["formulaName"] = obj?.formulaName;
                  child["description"] = obj?.description;
                  child["fieldType"] = obj?.fieldType;
                  // if(!child["labelClassNames"]){
                  //   child["labelClassNames"] = "";
                  // }
                }

                setNecessaryProperties(child);
              });
            }
          };

          setNecessaryProperties({
            items: designedItems,
          });

          this.designedItems = designedItems;
        }

        this.loader.customObject = false;
        this.$parent.isLoading = false;
      });
    },
  },
  computed: {
    filteredItems() {
      let data = this.customObjectItems.filter((x) => {
        return (
          x.name.toLowerCase().includes(this.searchText.toLowerCase()) &&
          !this.inUsedCustomObjectIdList.includes(x.id)
        );
      });

      let selectedFilters = [];
      let filteredData = [];

      if (this.showFields) {
        selectedFilters.push("field");
      }
      if (this.showLookups) {
        selectedFilters.push("lookup");
      }
      if (this.showButtons) {
        selectedFilters.push("button");
      }

      selectedFilters.forEach((x) => {
        filteredData = [
          ...filteredData,
          ...data.filter((y) => {
            return y.type === x;
          }),
        ];
      });

      return filteredData;
    },
  },
  methods: {
    findInUsedChildCustomObjectItems: function (el) {
      if (el && el.items?.length > 0) {
        el.items.forEach((child) => {
          if (
            child.type === "field" ||
            child.type === "lookup" ||
            child.type === "button"
          ) {
            this.inUsedCustomObjectIdList.push(child.id);
          }
          this.findInUsedChildCustomObjectItems(child);
        });
      }
    },
    onClone: function (el) {
      let clonedElement = JSON.parse(JSON.stringify(el));
      clonedElement.id = uuidv4();

      if (clonedElement.type === "tab") {
        clonedElement.items.forEach((x) => {
          x["id"] = uuidv4();
        });
      }

      return clonedElement;
    },
    onMove: function (element) {
      return onMove.check(element);
    },
    filterItemsByIsActive(result) {
      var filteredItems = result.data.items.filter(function (item) {
        return item.isActive;
      });

      if (
        result.config.url.indexOf("LookupRelationList") > -1 ||
        result.config.url.indexOf("ButtonList") > -1
      ) {
        filteredItems = filteredItems.map(function (m) {
          return {
            key: m.publicId,
            value: m.name,
          };
        });
      }

      return {
        data: {
          items: filteredItems,
          itemCount: filteredItems.length,
        },
        config: result.config,
      };
    },
    getFieldItems: function () {
      return new Promise((resolve, reject) => {
        this.$prodGatewayAxios
          .get(
            `/Lcdp-FieldList?customObjectPublicId=${this.$route.params.customObjectId}`
          )
          .then((response) => {
            resolve(this.filterItemsByIsActive(response));
          })
          .catch(function (error) {
            reject(error);
            //alert(error);
          });
      });
    },
    getLookupItems: function () {
      return new Promise((resolve, reject) => {
        this.$prodGatewayAxios
          .get(
            `/Lcdp-LookupRelationList?customObjectPublicId=${this.$route.params.customObjectId}`
          )
          .then((response) => {
            resolve(this.filterItemsByIsActive(response));
          })
          .catch(function (error) {
            reject(error);
            //alert(error);
          });
      });
    },
    getButtonItems: function () {
      return new Promise((resolve, reject) => {
        this.$prodGatewayAxios
          .get(
            `/Lcdp-ButtonList?customObjectPublicId=${this.$route.params.customObjectId}`
          )
          .then((response) => {
            resolve(this.filterItemsByIsActive(response));
          })
          .catch(function (error) {
            reject(error);
            //alert(error);
          });
      });
    },
    openObjectInNewWindow: function (element) {
      openObjectDetailHelper.open(element, this.$route.params.customObjectId);
    },
    getCustomObjectClass: function (type) {
      return customItemCssHelper.get(type).buttonClass;
    },
    getCustomObjectIcon: function (type) {
      return customItemCssHelper.get(type).icon;
    },
  },
};
</script>
