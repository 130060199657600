<template>
  <div
    class="border p-3"
    :class="[`border-${element.color}`, element.borderClass]"
  >
    <PreviewLayoutChild
      :customObjectItems="customObjectItems"
      :items="element.items"
    />
  </div>
</template>

<script>
import PreviewLayoutChild from "../PreviewLayoutChild.vue";

export default {
  props: ["element", "customObjectItems"],
  name: "BorderItem",
  components: {
    PreviewLayoutChild,
  },
};
</script>
