<template>
  <div
    :id-value="element.id"
    :name-value="element.name"
    @dblclick="openObjectInNewWindow(element)"
    class="custom-object border border-1 rounded bg-white d-flex justify-content-between align-items-center p-2"
    :class="getObjectBorderClass(element.type)"
    :style="{
      background: !String.isNullOrWhiteSpace(element.linearGradient)
        ? element.linearGradient
        : '',
      color: !String.isNullOrWhiteSpace(element.fontColor)
        ? element.fontColor
        : '',
    }"
  >
    <div class="d-flex justify-content-between align-items-center">
      <span :class="getCustomTextClass(element.type)"
        ><i class="bi bi-lg m-1" :class="getCustomObjectIcon(element.type)"></i
        >{{ element.name }}</span
      >
    </div>
  </div>
</template>

<script>
import customItemCssHelper from "../../helpers/CustomItemCssHelper";
import openObjectDetailHelper from "../../helpers/OpenObjectDetailHelper";

export default {
  props: ["element"],
  name: "CustomObjectItem",
  methods: {
    openObjectInNewWindow: function (element, isRedirectLink) {
      openObjectDetailHelper.open(
        element,
        this.$route.params.customObjectId,
        isRedirectLink
      );
    },
    getObjectBorderClass: function (type) {
      return customItemCssHelper.get(type).borderClass;
    },
    getCustomObjectIcon: function (type) {
      return customItemCssHelper.get(type).icon;
    },
    getCustomTextClass: function (type) {
      return customItemCssHelper.get(type).textClass;
    },
  },
};
</script>

<style scoped>
.custom-object {
  min-height: 3rem;
}
</style>
