<template>
  <draggable
    class="row min-size"
    :class="[{ 'row-border': parent.type === 'row' }, `element-${parent.type}`]"
    :list="items"
    item-key="id"
    handle=".handle"
    chosenClass="chosen"
    ghost-class="ghost"
    v-bind="{
      group: 'element-group',
      disabled: false,
      animation: 200,
      swapThreshold: 0.6,
      filter: '.locked-element',
    }"
    :disabled="locked"
    :move="onMove"
  >
    <template #item="{ element, index }">
      <DesignItem
        :key="element.id"
        :class="element.type === 'column' ? `${element.className}` : ''"
        :parent="parent"
        :element="element"
        :index="index"
        :customObjectItems="customObjectItems"
        :items="items"
      />
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import DesignItem from "./DesignItem.vue";
import onMove from "./helpers/OnMove";

export default {
  props: ["parent", "customObjectItems", "items"],
  name: "DesignerLayoutChild",
  components: {
    draggable,
    DesignItem,
  },
  beforeCreate() {
    this.$options.components.DesignItem = require("./DesignItem.vue").default;
  },
  methods: {
    onMove(element) {
      return onMove.check(element);
    },
  },
  computed: {
    locked() {
      return false; //this.parent.type === "container" || this.parent.type === "row";
    },
  },
};
</script>
