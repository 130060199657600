<template>
  <div
    class="alert"
    :class="`alert-${element.color}`"
    role="alert"
    :style="{
      background: !String.isNullOrWhiteSpace(element.linearGradient)
        ? element.linearGradient
        : '',
      color: !String.isNullOrWhiteSpace(element.fontColor)
        ? element.fontColor
        : '',
    }"
  >
    <h4 v-if="element.title !== ''" class="alert-heading">
      {{ element.title }}
    </h4>
    <div class="d-flex align-items-center">
      <i
        v-if="element.icon !== ''"
        :class="`me-1 bi bi-${element.icon} bi-xxlg`"
      />
      <div v-html="message" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["element"],
  name: "AlertItem",
  data() {
    return {
      message: this.element.message,
    };
  },
  created() {
    if (this.$store.state.isMultiLanguage) {
      var localizationValue = this.$root.getLocalizationValueForPageDesigner(
        this.element
      );
      if (localizationValue) {
        this.message = localizationValue.value;
      }
    }
  },
};
</script>

<style scoped>
.alert {
  margin-bottom: 0.5rem !important;
}
</style>
