<template>
  <div
    class="border bg-light p-2 sx-pagedesigner-localization-boxes"
    :id="`${idPrefix}`"
  >
    <div
      v-for="(language, i) in companyLanguages"
      :key="i"
      :class="{
        'mt-2': i > 0,
      }"
    >
      <label
        :for="`${idPrefix}-${language.languageCulture}`"
        class="form-label w-100"
        ><img
          :src="
            require(`@/assets/images/flags/${language.languageCulture}.png`)
          "
          class="img-fluid float-end"
          width="20"
        />
        {{ label }}</label
      >
      <textarea
        v-if="inputType === 'textarea'"
        class="form-control"
        :spellcheck="$isTextSpellCheck"
        :id="`${idPrefix}-${language.languageCulture}`"
        :name="`${idPrefix}-${language.languageCulture}`"
        :value="getValue(language)"
        @change="setValue($event, language)"
      />
      <input
        v-else
        type="text"
        class="form-control"
        :autocomplete="$isAutoComplete"
        :spellcheck="$isTextSpellCheck"
        :id="`${idPrefix}-${language.languageCulture}`"
        :name="`${idPrefix}-${language.languageCulture}`"
        :value="getValue(language)"
        @change="setValue($event, language)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "LocalizationBox",
  props: {
    inputType: {
      type: String,
      default: "text", //or textarea
    },
    label: {
      type: String,
      default: "",
    },
    itemData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      companyLanguages: this.$store.state.companyLanguages || [],
      values: (this.itemData && this.itemData.localization) || [],
      idPrefix: `sx-pagedesigner-localization-box-${
        (this.itemData && this.itemData.id) || String.newGuid()
      }`,
    };
  },
  methods: {
    setValue($event, language) {
      var parent = this.$parent,
        translationValue = $event.target.value;

      var currentLanguageTranslation = this.values.find(
        (f) => f.culture === language.languageCulture
      );
      if (currentLanguageTranslation) {
        currentLanguageTranslation.value = translationValue;
      } else {
        this.values.push({
          culture: language.languageCulture,
          value: translationValue,
        });
      }

      //parent emiting
      if (parent) {
        if (parent.editItem) {
          //for panel, alert item
          parent.editItem.localization = this.values;
        } else if (parent.list) {
          //for tab, accordion item
          parent.list.find((f) => f.id === this.itemData.id).localization =
            this.values;
        }
      }
    },
    getValue(language) {
      var translationValue = this.values.find(
        (f) => f.culture === language.languageCulture
      );
      if (translationValue) {
        return translationValue.value;
      }
    },
  },
};
</script>
