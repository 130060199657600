<template>
  <div class="text-dark" v-if="show">
    <div
      @click="edit"
      class="pointer"
      data-bs-toggle="offcanvas"
      :data-bs-target="`#offcanvas-designItemEdit-${designerLayoutBase.pageLayout.publicId}`"
      :aria-controls="`offcanvas-designItemEdit-${designerLayoutBase.pageLayout.publicId}`"
    >
      <i class="bi bi-pencil-square" :class="textColor"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "DesignItemEditButton",
  props: ["parent", "element", "show", "textColor"],
  data() {
    return {};
  },
  methods: {
    edit() {
      if (!this.designerLayoutBase) return;

      var designItemEdit = this.designerLayoutBase.$refs.designItemEdit;

      designItemEdit.uId = String.newGuid();
      designItemEdit.editItem = this.element;
      designItemEdit.editParent = this.parent;
      designItemEdit.vParent = this.$parent;
    },
  },
  computed: {
    designerLayoutBase() {
      let parent = this.$parent;
      while (parent && parent.$options.name !== "DesignerLayoutBase") {
        parent = parent.$parent;
      }
      return parent;
    },
  },
};
</script>
