import $ from "jquery";
export default {
  open: function (element, customObjectId, isRedirectLink) {
    if ($(".offcanvas").hasClass("show") && !isRedirectLink) {
      return;
    }
    switch (element.type) {
      case "field":
        window.open(
          `#/CustomObject/EditField/${customObjectId}&fieldId=${element.id}`,
          "_blank"
        );
        break;
      case "lookup":
        window.open(
          `#/CustomObject/EditObjectRelation/${customObjectId}&objectRelationId=${element.id}`,
          "_blank"
        );
        break;
      case "button":
        window.open(
          `#/CustomObject/EditButton/${customObjectId}&buttonId=${element.id}`,
          "_blank"
        );
        break;
    }
  },
};
