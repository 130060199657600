<template>
  <PanelItem
    v-if="element.type === 'panel'"
    :element="element"
    :customObjectItems="customObjectItems"
  />

  <CustomObjectItem
    v-else-if="['field', 'lookup', 'button'].includes(element.type)"
    :element="element"
  />

  <AlertItem v-else-if="element.type === 'alert'" :element="element" />

  <BorderItem
    v-else-if="element.type === 'border'"
    :element="element"
    :customObjectItems="customObjectItems"
  />

  <TabItem
    v-else-if="element.type === 'tab'"
    :element="element"
    :customObjectItems="customObjectItems"
  />

  <AccordionItem
    v-else-if="element.type === 'accordion'"
    :element="element"
    :customObjectItems="customObjectItems"
  />

  <div v-else :class="[element.type === 'container' ? element.className : '']">
    <PreviewLayoutChild
      :customObjectItems="customObjectItems"
      :items="element.items"
      v-if="element.container == true"
    />
  </div>
</template>

<script>
import PreviewLayoutChild from "./PreviewLayoutChild.vue";
import AccordionItem from "./PreviewItems/AccordionItem.vue";
import AlertItem from "./PreviewItems/AlertItem.vue";
import BorderItem from "./PreviewItems/BorderItem.vue";
import CustomObjectItem from "./PreviewItems/CustomObjectItem.vue";
import TabItem from "./PreviewItems/TabItem.vue";
import PanelItem from "./PreviewItems/PanelItem.vue";

export default {
  props: ["element", "index", "customObjectItems", "items"],
  name: "PreviewItem",
  components: {
    PreviewLayoutChild,
    AccordionItem,
    AlertItem,
    BorderItem,
    CustomObjectItem,
    TabItem,
    PanelItem,
  },
  data() {
    return {
      editItem: this.element,
      editItems: [],
      childItemIndex: 0,
    };
  },
  created: function () {
    this.editItems = this.items;
    this.childItemIndex = this.editItem?.items?.length ?? 0;
  },
};
</script>
