<template>
  <PreviewItem
    v-for="(element, index) in items"
    :key="element.id"
    :class="getClass(element)"
    :element="element"
    :index="index"
    :customObjectItems="customObjectItems"
    :items="items"
  />
</template>

<script>
import PreviewItem from "./PreviewItem.vue";

export default {
  props: ["customObjectItems", "items"],
  name: "PreviewLayoutChild",
  components: {
    PreviewItem,
  },
  beforeCreate() {
    this.$options.components.PreviewItem = require("./PreviewItem.vue").default;
  },
  methods: {
    getClass(element) {
      if (element.type === "row") {
        return "row";
      }
      return element.type === "column" ? `${element.className} p-2` : "";
    },
  },
};
</script>

<style scoped>
.row > [class*="col"] {
  /* padding-top: 0.3rem;
  padding-bottom: 0.3rem; */
  /* background-color: rgba(166, 166, 166, 0.05); */
  border: 1px solid rgba(66, 66, 66, 0.04);
}

.col:empty {
  background-color: rgba(166, 166, 166, 0.05);
  min-height: 4rem;
}
</style>
