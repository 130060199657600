export default {
  get: function (type) {
    switch (type) {
      case "field":
        return {
          icon: "bi-box",
          textClass: "text-success",
          borderClass: "border-success",
          buttonClass: "btn-outline-success",
        };
      case "lookup":
        return {
          icon: "bi-boxes", // bi-menu-button-fill
          textClass: "text-warning",
          borderClass: "border-warning",
          buttonClass: "btn-outline-warning",
        };
      case "button":
        return {
          icon: "bi-braces", // bi-box-arrow-in-down
          textClass: "text-primary",
          borderClass: "border-primary",
          buttonClass: "btn-outline-primary",
        };
    }
  },
};
