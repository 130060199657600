<template>
  <div class="gradient-picker" :style="{ background: gradient }">
    <div>
      <transition-group name="flip-list" tag="ul">
        <li
          v-for="(color, index) in colors"
          :key="color.id"
          :class="{ shake: color.last }"
        >
          <div class="gradient-picker-move">
            <button class="up bi" @click="up(index)" v-if="index > 0"></button>
            <button
              class="down bi"
              @click="down(index)"
              v-if="index < colors.length - 1"
            ></button>
          </div>
          <input
            type="text"
            v-model.trim="color.hex"
            maxlength="7"
            :style="{ color: color.hex }"
            :class="{ pin: color.disabled }"
            :disabled="color.disabled"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
          <button
            @click="lockColor(index)"
            class="bi"
            :class="{ locked: color.disabled }"
          ></button>
        </li>
      </transition-group>
      <div class="button-group">
        <div>
          <button class="bi" @click="addColor" id="gradient-color-add"></button>
          <transition name="fade">
            <button
              class="bi"
              @click="removeColor"
              id="gradient-color-remove"
              v-if="colors.length > 2"
            ></button>
          </transition>
          <transition name="fade">
            <input
              class="gradient-picker-deg form-control"
              type="number"
              placeholder="deg"
              min="0"
              max="360"
              v-model="degree"
            />
          </transition>
        </div>
        <button class="bi re-generate" @click="generateGradient"></button>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/gradient-picker.css";
export default {
  name: "GradientPicker",
  emits: ["onSelect"],
  props: ["value"],
  data() {
    return {
      colors: [],
      defaultColors: [
        { id: 0, hex: "#f12711", disabled: false },
        { id: 1, hex: "#f5af19", disabled: false },
      ],
      defaultColors2: [
        { id: 0, hex: "#f12711", disabled: false },
        { id: 1, hex: "#f5af19", disabled: false },
      ],
      id: 2,
      degree: 45,
      linearGradientStrFormat: "linear-gradient({0}deg",
    };
  },
  methods: {
    addColor() {
      if (this.colors.length >= 10) return;

      this.colors.push({
        id: this.id,
        hex: this.randomHex(),
        disabled: false,
      });
      this.id++;
    },
    removeColor() {
      if (this.colors[this.colors.length - 1].disabled == false) {
        if (this.colors.length > 2) {
          this.colors.pop();
        }
      }
    },
    generateGradient() {
      for (let i = 0; i < this.colors.length; i++) {
        if (this.colors[i].disabled === false)
          this.colors[i].hex = this.randomHex();
      }
    },
    lockColor(index) {
      this.colors[index].disabled === true
        ? (this.colors[index].disabled = false)
        : (this.colors[index].disabled = true);
    },
    randomHex() {
      return "#" + Math.random().toString(16).slice(2, 8);
    },
    up(index) {
      if (index > 0) {
        let tempColors = this.colors,
          temp = tempColors[index];

        tempColors.splice(index, 1, tempColors[index - 1]);
        tempColors.splice(index - 1, 1, temp);
        this.colors = tempColors;
      }
    },
    down(index) {
      if (index < this.colors.length - 1) {
        let tempColors = this.colors,
          temp = tempColors[index];

        tempColors.splice(index, 1, tempColors[index + 1]);
        tempColors.splice(index + 1, 1, temp);
        this.colors = tempColors;
      }
    },
    compareTwoArrayOfObjects(first_array_of_objects, second_array_of_objects) {
      return (
        first_array_of_objects.length === second_array_of_objects.length &&
        first_array_of_objects.every((element_1) =>
          second_array_of_objects.some(
            (element_2) =>
              element_1.id === element_2.id &&
              element_1.hex === element_2.hex &&
              element_1.disabled === element_2.disabled
          )
        )
      );
    },
  },
  mounted() {
    var self = this;
    if (!String.isNullOrWhiteSpace(this.value)) {
      var splitedColor = this.value.split(","),
        i = 0;

      this.degree = splitedColor[0].split("(").pop().replace("deg", "");
      splitedColor.forEach(function (e) {
        if (!e.includes("linear-gradient")) {
          self.colors.push({
            id: i++,
            hex: e.replace(")", ""),
            disabled: false,
          });
        }
      });
    } else {
      this.defaultColors.forEach(function (e) {
        self.colors.push(e);
      });
    }

    this.id = self.colors.length;
  },
  computed: {
    gradient() {
      if (!this.colors.length) return;

      let linearGradient = String.format(
        this.linearGradientStrFormat,
        this.degree
      );
      this.colors.forEach(function (e) {
        linearGradient += "," + e.hex;
      });
      linearGradient += ")";

      if (!this.compareTwoArrayOfObjects(this.colors, this.defaultColors2)) {
        this.$emit("onSelect", linearGradient);
      }

      return linearGradient;
    },
  },
};
</script>
