<template>
  <div
    class="modal fade"
    :id="`jsonPasteModal`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`jsonPasteModalLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5 class="modal-title" :id="`jsonPasteModalLabel`">
            <i class="bi bi-filetype-json"></i>
            {{
              $t(
                "Components.JsonPasteModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close btn-json-paste-modal-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="form-json-paste">
            <div class="row">
              <div class="col md-12">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  @onChange="onChangePageLayoutTemplate"
                  :allowEmpty="true"
                  :isDetailIconShow="false"
                  :isGatewayRequest="true"
                  :requestUrl="String.format('/Lcdp-GetPageLayoutTemplates')"
                />
              </div>
            </div>
            <textarea
              v-model="json"
              rows="10"
              class="form-control mt-2"
              :spellcheck="this.$isTextSpellCheck"
              required
            ></textarea>
          </form>
          <template v-if="json != null">
            <div class="row">
              <div class="col md-12">
                <div class="alert alert-warning mt-2" role="alert">
                  {{
                    $t(
                      "Components.JsonPasteModal.Warning",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="modal-footer p-4">
          <button
            type="button"
            class="btn btn-success btn-pl-layout-save"
            @click="updatePageLayoutWithPasteData"
          >
            <span>
              <i class="bi bi-save"></i>
              {{
                $t(
                  "Components.JsonPasteModal.Save",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "jsonPasteModal",
  props: ["pastePublicId"],
  data() {
    return {
      json: {},
      jsonIsNull: true,
      modelToUpdate: {},
      selectedPageLayoutTemplate: "",
      selectedPageLayoutTemplatePublicId: "",
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
    };
  },
  components: {},
  watch: {
    pastePublicId() {
      this.defaultProperties();
      this.getPageLayout();
    },
  },
  methods: {
    defaultProperties() {
      this.jsonIsNull = true;
      this.json = null;
    },
    getPageLayout() {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-PageLayoutGet?id={0}", this.pastePublicId))
        .then((response) => {
          this.modelToUpdate = response.data;
          this.json = this.modelToUpdate.layout;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    updatePageLayoutWithPasteData() {
      var form = $(".form-json-paste");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var buttonName = "btn-pl-layout-save",
        button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(String.format(".{0}", buttonName));

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.modelToUpdate.publicId = this.pastePublicId;

      let layout = JSON.parse(this.json);
      function clearUnnecessaryProperties(item) {
        if (item && item.items?.length > 0) {
          item.items.forEach((child) => {
            if (
              child.type === "field" ||
              child.type === "lookup" ||
              child.type === "button"
            ) {
              delete child["name"];
              delete child["formulaName"];
              delete child["description"];
              delete child["fieldType"];
            }
            clearUnnecessaryProperties(child);
          });
        }
      }

      clearUnnecessaryProperties({
        items: layout,
      });

      this.modelToUpdate.layout = JSON.stringify(layout);
      this.$prodGatewayAxios
        .post("/Lcdp-PageLayoutUpdate", { ...this.modelToUpdate })
        .then((r) => {
          var response = r.data;

          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          form.removeClass("was-validated");

          if (response.isOk) {
            this.$parent.getPageLayouts();
            $(".btn-json-paste-modal-close").trigger("click");
            createToast(
              this.$t(
                "Messages.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: "true",
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          } else {
            this.errors.push(response.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onChangePageLayoutTemplate(selected) {
      this.selectedPageLayoutTemplate = selected.key;
      this.selectedPageLayoutTemplatePublicId = selected.value;
      this.json = JSON.parse(this.selectedPageLayoutTemplate);
    },
  },
};
</script>
